<template>
    <div class="terms-of-service-edit">
        <md-card>
            <md-card-header>
                <h1 class="md-title">Financing Edit</h1>
            </md-card-header>
            <md-card-content>
                <email-editor :options="options" v-model="content" autoFitHeight></email-editor>
            </md-card-content>
            <md-card-actions>
                <md-button @click="save" class="md-raised md-primary" style="margin-right:10px">Save</md-button>
            </md-card-actions>
            <br>
        </md-card>
    </div>
</template>
<script>
import EmailEditor from "@/components/EmailEditor";
export default {
    components: {
        EmailEditor
    },
    computed: {
        options() {
            return {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // toggled buttons
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }], // superscript/subscript
                        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                        [{ direction: "rtl" }], // text direction
                        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        [{ font: [] }],
                        [{ align: [] }],
                        ["link", "image"],
                        ["clean"], // remove formatting button
                        ["link", "image"]
                    ]
                }
            };
        }
    },
    data() {
        return {
            _id: "",
            content: ""
        };
    },
    methods: {
        async get() {
            var result = await this.$store.dispatch("crud/get", {
                api: `settings/financing`
            });
            if (result) {
                this.content = result.value;
                this._id = result._id;
            }
        },
        async save() {
            let result = await this.$store.dispatch("crud/put", {
                api: `settings/financing`,
                data: {
                    value: this.content
                }
            });
            this.$store.commit("setMessage", "save success");
        }
    },
    mounted() {
        this.get();
    }
};
</script>
